/* Section Code */
.section {
    width:100vw;
    margin: 0 auto;
    display:flex;
    position:relative;
    flex-wrap:wrap;
    justify-content:center;
}

.section:nth-child(1) {
    min-height:70vh;
    padding-top:150px;
}
.section:nth-child(2) {
    padding-top:100px;
    background-color: #fdd4d4;
    padding-bottom:100px;
}
.section:nth-child(3) {
    background-color: #fdd4d4;
    padding-bottom:100px;
}
.section:nth-last-child(1) {
    padding-bottom: 100px;
}

.sectionTopWave {
    position:absolute;
    top:-20vw;
    width:100vw;
    z-index: -1;
}
.sectionBottomWave {
    position: absolute;
    top: -20vw;
    width: 100vw;
    z-index: 0;
}
/* Container code */
.containerHalf,
.containerHalfImg{
    width:40%;
    position:relative;
    min-height:10px;
    min-height:300px;
    display:inline-block;
    margin:auto 0;
}
/* Image css */
#displayOne {
    width:400px;
    border: 2px solid var(--color-grey);
    border-radius: 10px;
    margin-left:30%;
    margin-top:-10px;
}
.containerHalfImg img {
    width:100%;

}
/* Button css */
.button {
    display:inline-block;
    text-align: center;
    text-decoration: none;
    padding: 0px 20px;
    min-width:175px;
    height:50px;
    margin:20px 20px 3px 0px;
    background-color: transparent;
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
    font-size: 1.5rem;
    border-radius: 10px;
    transition: all 0.3s;
}
a.button {
    padding-top:10px;
}
.button:hover,
.buttonInvert:hover {
    background:linear-gradient(to right, hsl(270, 72%, 51%), var(--color-primary));
    color: var(--color-grey);
    cursor:pointer; 
    box-shadow: 2px 2px 5px #888888;
    border-color:transparent;
    opacity:90%;
}
/* Header Css */
.h1 {
    font-size:4.3rem;
    color:transparent;
    background: linear-gradient(to right, hsl(270, 100%, 60%),10%, var(--color-primary));
    background-clip: text;
    flex-shrink:0;
}
.h3 {
    font-size: 2.2rem;
    color: var(--color-primary);
    max-width: 80vw;
    margin-bottom: 30px;
}
/* Text Css */
.p {
    font-size:1.3rem;
    color: rgb(101, 101, 101);
    font-family:'Times New Roman', Times, serif;
    width:90%;
}

/* Media Queries */

@media screen and (max-width: 900px) {
    .containerHalf,
    .containerHalfImg {
        text-align:center;
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        width:70vw;
        height:fit-content;
    }
    .containerHalfImg {
        width:100vw;
    }
    #displayOne {
        margin:0 auto;
        width:90vw;
    }
    .button {
        width:90%;
        margin:10px 0;
    }
    .p,
    .h1,
    .h3 {
        text-align: center;
    }
}

@media screen and (max-width: 700px) {
    .h1 {
        font-size:2.5rem;
    }
    .h3 {
        font-size: 1.4rem;
    }
}
