.faqContainer {
    padding: 0;
    margin: 0 auto;
    width: 80vw;
    height: 100%;
}

.contactContainer {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width:100vw;
}
.contactForum {
    width: 30vw;
    text-align: center;
    padding:2.5vw;
    text-align: center;
    margin: 10vh 5vw;
    background-color: #fdd4d4;
    color: var(--color-primary);
    border-radius: 5px;
    box-shadow: 1px 1px 5px -1px #000000;
    transition: all 0.3s ease-in;
    cursor: pointer;

}
.contactForum h1 { 
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1vw;
}
.contactForum input,
.contactForum textarea,
.contactForum button {
    width:100%;
    height:50px;
    margin-top:10px;
    border:none;
    border-radius:5px;
    background-color:var(--color-primary);
    color:white;
    font-size:1rem;
    font-weight:bold;
    cursor:pointer;
    text-align: center;
    justify-content: center;
}

.contactForum textarea {
    padding-top:20px;
    height:130px;
}

.mailImg {
    width: 30%;
    height:min-content;
}

@media screen and (max-width: 600px) {
    .contactForum {
        display:block;
        width: 90vw;
        margin: 0;
    }
    .mailImg {
        width: 100%;
    }
}