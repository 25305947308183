.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f1f1f1;
    padding: 20px;
    z-index: 1001;
    width:40vw;
    text-align: center;
    border-radius: 20px;
}

.container h1{
    font-size: 30px;
    color: #2196F3;
    padding:30px;
}

.container p {
    font-size: 20px;
    color: #555;
    padding:30px;
}

.container h2 {
    font-size: 20px;
    color: #555;
    padding:30px;
}

@media screen and (max-width: 1000px) {
    .container {
        width:90vw;
    }
}

@media screen and (max-width: 600px) {
    .container h1 {
            font-size: 20px;
        }

        .container p {
            font-size: 15px;
        }

        .container h2 {
            font-size: 15px;
        }
}
    