.footer {
    background-color:#9f9898;
    display:flex;
    padding-left:40px;
    min-height:60px;
    align-items: center;
}
.footer p {
    color:white;
    font-size:12px;
    margin-left:10px;
}