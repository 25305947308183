.dropDown {
    position: absolute;
    top: 100%;


    padding: 10px 15px;
    list-style: none;
    box-shadow: 1px 1px 5px -1px #000000;
    border-radius: 5px;

    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in;
    background-color: white;
}


.dropDown li {
    padding-top: 10px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: .1s ease-in, padding .2s ease-in;
    padding-right:4px;
}

.dropDown li:hover {
    border-bottom: 2px solid var(--color-primary);
    color:black;
    padding-left:4px;
    padding-right:0;
}

li:hover .container *+.dropDown,
.container:hover * + .dropDown,
 :hover + .dropDown {
    /* display: block; */
    opacity: 1;
    pointer-events: all;
}
.arrowDown {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid var(--color-primary);
    display:inline-block;
    height: 1px;
    padding:0;
    margin-left:-5px;
}

@media screen and (max-width: 1000px) {
    .dropDown {
        display:none;
    }
}