.faqCard {
    width:95%;
    padding:10px 30px;
    margin:20px 0;
    height:100%;
    background-color: var(--color-primary);
    color:white;
    box-shadow:1px 2px 3px rgb(108, 108, 108);
    border-radius:3px;
}

.faqCardQuestion {
    font-size:1.3em;
    font-weight:bold;
    cursor: pointer;
    border-bottom:2px solid #fdd4d4;
}
.faqCardQuestion:focus + .faqCardAnswer {
    opacity: 1;
    height:50px;
}
.faqCardAnswer {
    font-size:1.1em;
    height:0px;
    opacity: 0;
    pointer-events: none;
    transition: height 0.5s ease-in-out, opacity 0.5s .0s ease-in-out;
    overflow: hidden;
}