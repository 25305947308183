.container {
    color:rgb(101, 101, 101);
    margin-top:50px;
    text-align: left;
}
.list {
    list-style: none;
    height:70px;
    margin-top:10px;
    display: block;
    justify-content: space-between;
    width:100%;
}
.list li {
    display:inline-block;
    max-width:70px;
    max-height:70px;
    margin-right:10px;
    width:15%;
}
.list img {
    width:100%;
    height:100%;
    border-radius: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 1000px) {
    .container {
        width:80vw;
        min-height:0px;
        min-width:0;
        margin: 20px auto;
    }
    .list {
        width:100%;
        height:auto;
    }
}