* {
  margin: 0;
  padding: 0;
}
body {
  --color-primary: #9933ff;
  --color-secondary: hsl(45, 100%, 61%);
  --color-tertiary: hsl(28, 100%, 58%);
  --color-accent: hsl(0, 100%, 64%);
  --color-grey: hsl(0, 0%, 88%);
  --color-offblack: hsl(0, 0%, 20%);

  padding:0;
  font-family: 'Roboto', sans-serif;

  color:var(--color-offblack);
  line-height:1.5;
}