.nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background-color:white;
    box-shadow: 5px 2px 5px #888888;
    z-index: 1000;
}
.backdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
    display:none;
}
.list{
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
}

.items,
.login {
    display:inline-block;
    margin: 0 10px;
    font-size: 1.1rem;
    color: var(--color-offblack);
    cursor: pointer;
    text-decoration: none;
}
li.items:hover {
    padding-top:20px;
    padding-bottom:10px;
    transition: padding 0.3s, border .1s.2s; 
    border-bottom: 4px solid var(--color-primary);
}

.login {   
    display:inline-block; 
    padding:7px 20px;
    background: linear-gradient(45deg, #fdd4d4,25%, var(--color-primary));
    border-radius: 15px;
    margin-right: 5vw;
    margin-left:6vw;
    transition:all 0.3s ease-in-out;
    letter-spacing: 2px;
    color:white;
    text-shadow: 2px 2px 5px #535353;
}
.login:hover {
    text-shadow: 0px 0px 3px var(--color-white);
    box-shadow: 2px 2px 5px #414141;
    color:white;
}
.login:after {
    width:0px;
}
.hambergerBtn {
    display: none;
    position: absolute;
    border:none;
    background-color: transparent;
    right: 10px;
    top: 7px;
}
.img {
    width: 40px;
    height: 40px;
    cursor:pointer;
}

.title {
    font-size: 1.5rem;
    position:absolute;
    left:10vw;
    top:50%;
    height:50%;
    transform:translate(0,-50%);
    cursor: pointer;
}


@media screen and (max-width: 1060px) {
    .list {
        flex-direction: column;
        height: auto;
        top:68px;
        background-color:white;
        border-radius:20px;
        width:90vw;
        left:5vw;
    }
    .items,
    .login {
        width: 70vw;
        max-width: 500px;
        text-align: center;
        margin-right: 0px;
        margin:10px auto;
        z-index:100;
        min-width:0;
    }
    .hambergerBtn {
        display: block;
    }
    .backdrop {
        display: block;
    }
}