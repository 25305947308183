.container {
    background-image: url("./../../../public/images/app-background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.5);
}

.header {   
    width:80%;
    margin:0px auto; 
    padding: 10px;
}
.header h1,
.features h1 {
    font-size: 2.5em;
    font-weight: 700;
    margin-top:20px;
    text-align: center;
    color: transparent;
    background: linear-gradient(to right, hsl(270, 100%, 60%), 30%, var(--color-primary));
    background-clip: text;
}
.header p {
    font-size: 1.2em;
    font-weight: 300;
    margin-top: 20px;
    text-align: center;
    color:var(--color-primary);
    font-family: 'Times New Roman', Times, serif;
}

.pricing {
    width:80%;
    margin:20px auto;
    display:flex;
    justify-content: space-between;
    align-content: center;

}
.card {
    display:inline-block;
    width:380px;
    padding:20px;
    height:500px;
    margin:20px;
    border: 2px solid var(--color-primary);
    text-align:center;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
    color: rgb(101, 101, 101);
    display:flex;
    flex-direction:column;
    border-radius:6px;
    background:white;
    /* background:linear-gradient(-135deg, white, #fdd4d4);  */
    /* justify-content:space-between; */
}
.card:nth-child(2) {
    padding:45px 20px; 
    margin-top:-5px;
}
.card p {
    font-family: 'Times New Roman', Times, serif;
}
.card h1 {
    font-size:2.3rem;;
    background-clip: text;
    color:hsl(270, 100%, 60%);
    margin-bottom:10px;
}
.card h2 { 
    margin-top: auto;
    color:hsl(270, 100%, 60%);
    display:inline;
    font-size:2.5rem;
}
.card .miniText {
    margin-top:-10px;
    font-size:.7rem;
    /* color:#fdd4d4    */
}

.card h3 {
    font-size:1.2rem;
    margin-top:auto;
    color:hsl(270, 100%, 60%);
}
.card ul {
    text-align:left;
    width:fit-content;
    margin:0 auto;
    font-family: 'Times New Roman', Times, serif;
    list-style:none;
}
.card li {
    font-size:1.2rem;
    margin-top:20px;
    line-height:.5rem;
    /* color:var(--color-primary); */
}
.card a {
    display:block;
    color:white;
    background: hsl(270, 100%, 60%);
    text-decoration:none;
    width:100%;
    font-size:20px;
    padding: .5em 0;
    margin-top:auto;
    box-shadow: 3px 3px 3px rgba(98, 98, 98, 0.1);
    transition: transform .2s ease-in-out;
}
.card a:hover {
    transform:scale(1.07)
}
.microText {
    border-bottom:2px solid #fdd4d4;
    width:80vw;
    margin:20px auto;

}
.microText p {
    font-size:1.5rem;
    text-align:center;
}
.microText p a { 
    color:hsl(270, 100%, 60%);
    text-decoration: none;
}
.table {
    width:80%;
    margin:0 auto;
    /* padding:50px 0; */
    text-align:center;
    border-left: 4px solid var(--color-primary);
    /* padding-right:-20px; */
}
.table th {
    font-size:1.2rem;
    font-weight:700;
    color:hsl(270, 100%, 60%);
    background-color:white;
}
.table td {
    padding:20px;
    font-size:1.2rem;
    font-weight:300;
    color: rgb(101, 101, 101);
    margin:0;
    background:white;

}
@media (max-width: 1100px) {
    .pricing{
        flex-direction: column;
        align-items: center;
        width:90%;
    }
    .card {
        width:90%;
        max-width:500px;
    }
    .features {
        display:none;
    }
}