.ImgBanner {
    width:100vw;
    min-height:60vh;
    background-image:url("../../../public/images/media-background.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:0;
    margin:0;
}

.ImgBanner h1 { 
    font-size:3em;
    font-weight:bold;
    color:white;
    text-shadow:1px 1px 1px black;
    margin:0;
    padding: 5px 10px;
    background-color:var(--color-primary);
    border-radius:5px;
    box-shadow: 0px 0px 200px 40px white;
}